import { TileBase } from "../dashboards/tilebase";
import { TileFactory } from "../dashboards/tilefactory";
import { TileSettingsBase } from "../dashboards/tilesettingsbase";
import "devextreme/ui/progress_bar";
import { TDFRequest } from "../../services/request";
import { GetDevice, DomSafeID } from "../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxArrayStore from "devextreme/data/array_store";
export class LegacyByorTileSettings extends TileSettingsBase {
  ReportTitle: string;
  BSID?: string;
  BSIDType?: string;
  SavedSelection?: string;
  DisplayInTileMode: boolean = true;
}

export class LegacyByorTile extends TileBase {
  protected _Name: string = "BYOR Report";
  private readonly ReportListURL: string = "/BYOR/ReportList/";
  public readonly DefaultHeight: string = "500px";

  readonly CheckReportURL: string = "/BYOR/CheckReport/";
  readonly GetRenderedReportURL: string = "/BYOR/GetFinalReport/";
  readonly GetReportHtmlURL: string = TDFRequest.ApiPath + "/BYOR/GetReportHTML/";

  private ReportID: string = "";
  private ProgressBar: DevExpress.ui.dxProgressBar;
  private ReportCheckTimeout;
  //private GetRenderedReportOverride: Function;

  // IDs
  private ReportContainerID: string = "";
  private ReportProgressAreaDivID: string;
  private ReportSpinnerContainerID: string;
  private ReportProgressBarDivID: string;
  private ReportTitleSelectorID: string;

  constructor(tileInstanceID: string, tileSettings: LegacyByorTileSettings, settingsClassRef: typeof TileSettingsBase = LegacyByorTileSettings) {
    super(tileInstanceID, tileSettings, settingsClassRef);

    let legacyReportTile = this;
    legacyReportTile.SetIds();
  }

  public Render(contentElement: JQuery): JQueryPromise<any> {
    let legacyReportTile = this;

    let content: string = `<div id="${legacyReportTile.ReportContainerID}"></div>`;
    $(contentElement).append(content);

    $(`#${legacyReportTile.ReportContainerID}`).attr("style", "height: 100%");

    legacyReportTile.SubmitByorForTile();

    //TODO:  Make this resolved more correctly.
    let dfd: JQueryDeferred<any> = $.Deferred();
    return (dfd.promise(dfd.resolve));
  }

  public GetConfigurableTileSettings() {
    let legacyReportTile = this;

    let dfd = $.Deferred();

    legacyReportTile.GetReportList().done(function (reportList) {
      let items = [
        {
          dataField: "TileSettings.ReportTitle",
          editorType: "dxSelectBox",
          editorOptions: {
            elementAttr: {
              id: legacyReportTile.ReportTitleSelectorID
            },
            dataSource: new dxDataSource({
              store: new dxArrayStore({
                data: reportList.map(function (item) { return item.Title; }),
              }),
            }),
            width: GetDevice().isDevice ? "125px" : "300px"
          }
        }
      ];

      dfd.resolve(items);
    });

    return dfd.promise();
  }

  private GetReportList = function (): JQueryPromise<any> {
    var theByorReports = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    var request = new TDFRequest({ url: theByorReports.ReportListURL, type: "Get", data: {} });
    request.MakeRequest().done(function (reportItems) {
      return (dfd.resolve(reportItems));
    });

    return dfd.promise();
  };

  private SubmitByorForTile() {
    let legacyReportTile = this;
    let mySettings: LegacyByorTileSettings = <LegacyByorTileSettings>legacyReportTile.Settings;

    let submitReportURL = "/BYOR/SubmitReportRequest/";
    let data: any = {
      reportTitle: mySettings.ReportTitle.replace("#", "%23")
    };

    if (mySettings.BSID) {
      data._bsid = mySettings.BSID;
    }
    if (mySettings.BSIDType) {
      data.bsidType = mySettings.BSIDType;
    }
    //TODO:  Add savedSelection option

    let request = new TDFRequest({ url: submitReportURL, type: "Get", data: data });
    request.MakeRequest().done(function (response) {
      legacyReportTile.LoadReport(response.ReportID);
    });
  }

  private LoadReport(reportID: string) {
    var legacyReportTile = this;
    legacyReportTile.ReportID = reportID;

    let html = $("<div><h3><strong>Report Loading....</strong></h3></div>")
      .append(
        $("<div id='" + legacyReportTile.ReportProgressAreaDivID + "' class='ReportProgressArea' />")
          .append($("<div id='" + legacyReportTile.ReportSpinnerContainerID + "' class='ReportSpinner' />"))
          .append($("<div id='" + legacyReportTile.ReportProgressBarDivID + "' />"))
      );

    $("#" + legacyReportTile.ReportContainerID).append(html);

    var spinnerRequest = new TDFRequest({ url: "", type: "GET", data: {} });
    spinnerRequest.spinnerContainerID = legacyReportTile.ReportSpinnerContainerID;
    spinnerRequest.LoadSpinner();

    legacyReportTile.ProgressBar = $("#" + legacyReportTile.ReportProgressBarDivID).dxProgressBar({
      max: 100,
      min: 0,
      showStatus: true,
      statusFormat: function (value) {
        return "Loading: " + (value * 100).toFixed() + "%";
      },
      visible: true
    }).dxProgressBar("instance");

    var request = new TDFRequest({ url: legacyReportTile.CheckReportURL, type: "GET", data: { reportID: legacyReportTile.ReportID } });
    request.ShouldShowSpinner = false;
    request.MakeRequest().done(function (response: any) {
      if (true) {
        let percent: number = parseFloat(response.statusPercent) * 100;
        legacyReportTile.ProgressBar.option("value", percent);

        if (response.statusPercent === 1) {
          legacyReportTile.GetRenderedReport();
        } else {
          legacyReportTile.ReportCheckTimeout = setInterval(legacyReportTile.CheckReport.bind(legacyReportTile), 1000);
        }
      }
    });
  }

  private CheckReport() {
    var legacyReportTile = this;
    
    clearInterval(legacyReportTile.ReportCheckTimeout);
    var request = new TDFRequest({ url: legacyReportTile.CheckReportURL, type: "GET", data: { reportID: legacyReportTile.ReportID } });
    request.ShouldShowSpinner = false;
    request.MakeRequest().done(function (response: any) {
      let percent: number = parseFloat(response.statusPercent) * 100;
      legacyReportTile.ProgressBar.option("value", percent);

      if (response.statusPercent === 1) {

        legacyReportTile.GetRenderedReport();
      }
      else {
        legacyReportTile.ReportCheckTimeout = setInterval(legacyReportTile.CheckReport.bind(legacyReportTile), 1000);
      }
    });
  }

  private GetRenderedReport() {
    var legacyReportTile = this;

    //NEEDTODO - Make the GetToken method reachable without creating a TDFRequest object.
    let fakeReq = new TDFRequest({ url: "test" });
    fakeReq.GetToken().done(token => {
      if (legacyReportTile.ReportSettings.DisplayInTileMode) {
        legacyReportTile.LoadByorInTile();
      } else {
        let newUrl = legacyReportTile.GetReportHtmlURL + "?reportID=" + legacyReportTile.ReportID + "&reportTitle=" + legacyReportTile.ReportSettings.ReportTitle;

        if (legacyReportTile.ReportSettings.SavedSelection) {
          newUrl += "&savedSelection=" + legacyReportTile.ReportSettings.SavedSelection;
        }

        if (legacyReportTile.ReportSettings.BSID) {
          newUrl += "&bsid=" + legacyReportTile.ReportSettings.BSID;
        }

        if (legacyReportTile.ReportSettings.BSIDType) {
          newUrl += "&bsidType=" + legacyReportTile.ReportSettings.BSIDType;
        }

        newUrl += "&access_token=" + token;

        // TODO:  Replace URL to GetRenderedReportURL when the issues with the secondary DOM are worked out.
        var request = new TDFRequest({ url: newUrl, type: "GET", data: {} }); // making request for use of spinner
        request.spinnerContainerID = legacyReportTile.ReportSpinnerContainerID;
        request.LoadSpinner();
        window.location.href = newUrl;
      }
    });
  }

  private LoadByorInTile() {
    let legacyReportTile = this;

    //NEEDTODO - Make the GetToken method reachable without creating a TDFRequest object.
    let fakeReq = new TDFRequest({ url: "test" });
    fakeReq.GetToken().done(token => {

      let reportHtmlURL = legacyReportTile.GetReportHtmlURL + "?reportID=" + legacyReportTile.ReportID + "&reportTitle=" + encodeURIComponent(legacyReportTile.ReportSettings.ReportTitle.replace("#", "%23"));

      if (legacyReportTile.ReportSettings.BSID) {
        reportHtmlURL += "&_bsid=" + legacyReportTile.ReportSettings.BSID;
      }
      if (legacyReportTile.ReportSettings.BSIDType) {
        reportHtmlURL += "&bsidType=" + legacyReportTile.ReportSettings.BSIDType;
      }
      //TODO:  Add savedSelection option
      reportHtmlURL += "&access_token=" + token;

      let html = `<iframe src="${reportHtmlURL}" style="width:100%;height:100%"></iframe>`;
      $(`#${legacyReportTile.ReportContainerID}`).empty();
      $(`#${legacyReportTile.ReportContainerID}`).append(html);
    });
  }

  private SetIds() {
    let legacyReportTile = this;
    let domSafeID = DomSafeID(legacyReportTile.TileInstanceID);

    legacyReportTile.ReportContainerID = `reportArea_${domSafeID}`;
    legacyReportTile.ReportProgressAreaDivID = "progressArea" + domSafeID;
    legacyReportTile.ReportSpinnerContainerID = "reportSpinner" + domSafeID;
    legacyReportTile.ReportProgressBarDivID = "reportProgressBar" + domSafeID;
    legacyReportTile.ReportTitleSelectorID = `reportSettingsTitleSelector_${domSafeID}`;
  }

  private get ReportSettings(): LegacyByorTileSettings {
    let legacyReportTile = this;
    return <LegacyByorTileSettings>legacyReportTile.Settings;
  }
}

// TileFactory.RegisterTileType("LegacyBYOR", "A tile that can be used to display a legacy BYOR report.", "fa fa-line-chart", LegacyByorTileSettings, LegacyByorTile);
